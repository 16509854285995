"use client"
import {Accordion, AccordionItem} from "@nextui-org/accordion"
import {Button} from "@nextui-org/button"
import {Card, CardBody} from "@nextui-org/card"
import {Tooltip} from "@nextui-org/tooltip"
import {Link} from "@repo/i18n/navigation"
import {cn} from "@repo/utils/utils"
import type {ChineseNameDataType, ChineseNamesDataArrayType,} from "@repo/web/lib/actions/chinese-names/types.ts"
import {CopyButton, TextAudioButton, useFavorites,} from "@repo/web/lib/components"
import {FAVORITES_SS_KEY} from "@repo/web/lib/consts"
import {downloadJson, fetchPost} from "@repo/web/lib/utils"
import {FileDown, FileJson, Heart, X} from "lucide-react"
import {useTranslations} from "next-intl"
import {type FC, memo, type ReactNode, useCallback, useMemo, useState,} from "react"
import {useCopyToClipboard} from "react-use"

type FavoriteButtonProps = {
    className?: string
    classNames?: {
        wrapper?: string
        icon?: string
    }
    toggleable?: boolean
    disabled?: boolean
    defaultFavorite?: boolean
    size?: number
    onClick: () => void
}
const FavoriteButton: FC<FavoriteButtonProps> = ({
                                                     className,
                                                     classNames,
                                                     defaultFavorite = false,
                                                     size = 18,
                                                     disabled = false,
                                                     toggleable = true,
                                                     onClick,
                                                 }) => {
    const [isFavorite, setIsFavorite] = useState(defaultFavorite)
    const handleClick = () => {
        if (!toggleable || isFavorite || disabled) {
            return
        }
        onClick()
        setIsFavorite(true)
    }
    return (
        <div
            className={cn(
                "inline-flex cursor-pointer transition delay-100 hover:scale-110 duration-500",
                className,
                classNames?.wrapper,
                {disabled},
            )}
        >
            {
                <Heart
                    size={size}
                    className={cn("size-5", classNames?.icon, {
                        "fill-primary": isFavorite,
                        "text-primary": isFavorite,
                        visible: isFavorite,
                    })}
                    onClick={handleClick}
                />
            }
        </div>
    )
}

type NameCardProps = ChineseNameDataType & {
    isPending?: boolean
    isFavorite?: boolean
    onCopy: (e: string) => void
    onFavorite: (e: string) => void
}
export const NameCard = memo<NameCardProps>(
    ({
         name,
         surname,
         lastname,
         pinyin,
         tone,
         meaning,
         explain,
         isFavorite,
         isPending = false,
         onCopy,
         onFavorite,
     }) => {
        const t = useTranslations("ChineseNamesGenerator")
        const [isHovered, setIsHovered] = useState(false)
        return (
            <Card
                className="w-full bg-[#F9F9F9] transition-all delay-100 ease-in-out duration-500 hover:scale-105 active:bg-gray-50"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <CardBody className="md:p-4">
                    <div className="flex justify-between items-baseline md:items-start gap-4">
                        <div className="inline-block min-w-[85px]">
                            <p className="text-base text-gray-500 mb-1 md:hidden">{tone}</p>
                            <Link href={`/tools/chinese-names-generator/names/${name}`}>
                                <span className="text-3xl md:text-2xl font-bold underline-animation">
                                    {name}
                                </span>
                            </Link>
                        </div>

                        <div className="hidden md:inline-flex md:justify-between md:items-center md:gap-2">
                            <p className="text-sm text-gray-500 hidden md:block">{tone}</p>
                            <TextAudioButton text={name}/>
                        </div>

                        <div className="flex-1 md:hidden">
                            <div className={"flex justify-end gap-3 transition-all delay-100 duration-500 md:hidden"}>
                                <TextAudioButton text={name} classNames={{'icon': 'size-6'}}/>
                                <CopyButton classNames={{'icon': 'size-6'}} onClick={() => onCopy(name)}/>
                                <FavoriteButton
                                    classNames={{'icon': 'size-6'}}
                                    disabled={isPending}
                                    defaultFavorite={isFavorite}
                                    onClick={() => onFavorite(name)}
                                />
                            </div>

                            <p className="text-sm mt-2">
                                {meaning && meaning.length > 0 && (
                                    <>
                                        <span className="text-gray-500 mr-1">{t("results.meaning")} : </span>
                                        {meaning}
                                    </>
                                )}
                            </p>
                            <p className="text-xs text-gray-500 md:mb-4">{explain}</p>
                        </div>


                    </div>
                    <p className="hidden md:block text-sm mt-2">
                        {meaning && meaning.length > 0 && (
                            <>
                                <span className="text-gray-500 mr-1">{t("results.meaning")} : </span>
                                {meaning}
                            </>
                        )}
                    </p>
                    <p className="hidden md:block text-xs text-gray-500 md:mb-4">{explain}</p>
                    <div className="justify-between items-center hidden md:flex">
                        <div className="flex items-center">
                            {/*<span className="text-yellow-500 mr-1">★</span>*/}
                            {/*<span className="font-bold">{rating}</span>*/}
                        </div>
                        <div
                            className={cn(
                                "flex gap-2 invisible transition-all delay-100 duration-500",
                                {visible: isHovered},
                            )}
                        >
                            <CopyButton onClick={() => onCopy(name)}/>
                            <FavoriteButton
                                disabled={isPending}
                                defaultFavorite={isFavorite}
                                onClick={() => onFavorite(name)}
                            />
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    },
)
NameCard.displayName = "NameCard"

type NameCardCompactProps = Pick<NameCardProps, "name" | "pinyin" | "tone" | "onCopy"> & {
    leading?: ReactNode
    onDrop?: () => void
}

export const NameCardCompact = memo<NameCardCompactProps>(
    ({
         name, pinyin, tone, onCopy, onDrop
     }) => {
        return (
            <Card
                className="w-full bg-[#F9F9F9] transition-all delay-100 ease-in-out duration-500 hover:scale-105 active:bg-gray-50">
                <CardBody className="p-4">
                    <div className="flex justify-between items-center">
                        <p className="inline-flex items-center gap-1">
                            {
                                onDrop && (
                                    <Button isIconOnly
                                            variant="light"
                                            size="sm"
                                            onClick={onDrop}>
                                        <X/>
                                    </Button>
                                )
                            }
                            <Link
                                className="text-xl font-bold underline-animation"
                                href={`/tools/chinese-names-generator/names/${name}`}
                            >
                                {name}
                            </Link>
                            <span className="ml-1 text-xs text-gray-400">{tone}</span>
                        </p>
                        {
                            onCopy && (<CopyButton onClick={() => onCopy(name)}/>)
                        }
                    </div>
                </CardBody>
            </Card>
        )
    },
)
NameCardCompact.displayName = "NameCardCompact"

type CopyAndDownloadProps = {
    data: ChineseNamesDataArrayType
}

export const CopyAndDownload = memo<CopyAndDownloadProps>(
    ({data}) => {
        const t = useTranslations("ChineseNamesGenerator")
        const [, copy] = useCopyToClipboard()
        const [isStringify, setIsStringify] = useState(false)
        const copyData = data.map((it) => (
            {
                name: it.name,
                surname: it.surname,
                lastname: it.lastname,
                pinyin: it.pinyin
            }
        ))
        const handleCopyJson = () => {
            setIsStringify(true)

            copy(JSON.stringify(copyData, null, 2))
            setIsStringify(false)
        }

        const handleDownJson = () => {
            setIsStringify(true)
            downloadJson(JSON.stringify(copyData, null, 2))
            setIsStringify(false)
        }
        return <>
            <div className="inline-flex items-center gap-2">
                <Tooltip content={t("results.btn-copy-json-tip")}>
                    <Button
                        isLoading={isStringify}
                        isDisabled={data.length <= 0}
                        isIconOnly
                        variant="flat"
                        onClick={handleCopyJson}
                    >
                        <FileJson size={20}/>
                    </Button>
                </Tooltip>
                <Tooltip content={t("results.btn-down-json-tip")}>
                    <Button
                        isLoading={isStringify}
                        isDisabled={data.length <= 0}
                        isIconOnly
                        variant="flat"
                        onClick={handleDownJson}
                    >
                        <FileDown size={20}/>
                    </Button>
                </Tooltip>
            </div>
        </>
    }
)
CopyAndDownload.displayName = "CopyAndDownload"

type NameCardListProps = {
    title?: ReactNode
    isPending?: boolean
    toolId: number
    data: ChineseNamesDataArrayType
    empty?: ReactNode
}


export const NameCardList = memo<NameCardListProps>(
    ({toolId, isPending = false, title, data, empty}) => {
        const t = useTranslations("ChineseNamesGenerator")
        const [, copy] = useCopyToClipboard()

        const {isFavorite, addFavorite} = useFavorites<ChineseNameDataType>(
            FAVORITES_SS_KEY + toolId,
            (it, v) => it.name === v,
        )

        const handleCopy = useCallback(
            (name: string) => {
                if (isPending) {
                    return
                }
                copy(name)
            },
            [copy, isPending])

        const handleFavorite = useCallback(async (name: string) => {
            if (isPending) {
                return
            }
            return fetchPost("/api/tools/chinese-names-generator/favorite", {
                name,
                toolId,
            }).then(() => addFavorite({name} as any))
        }, [addFavorite, isPending, toolId])

        const renderedCards = (items: ChineseNamesDataArrayType) => {
            if (data?.length > 0) {
                return (
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
                        {items.map((item: any) => (
                            // 假定每个名字是唯一的

                            <NameCard
                                key={item.name}
                                {...item}
                                isPending={isPending}
                                onCopy={handleCopy}
                                onFavorite={handleFavorite}
                            />

                        ))}
                    </div>
                )
            } else {
                return (
                    empty ?? (
                        <div className="mx-auto text-center py-20 text-gray-400 text-medium">
                            {t("previous-results.empty")}
                        </div>
                    )
                )
            }
        }
        return (
            <div className="w-full mx-auto">
                {title ?? (
                    <div className="flex items-center justify-between mb-4">
                        <div className="text-2xl font-bold ">
                            {t("results.title")}
                        </div>
                        <CopyAndDownload data={data}/>
                    </div>
                )}
                {renderedCards(data)}
            </div>
        )
    },
)
NameCardList.displayName = "NameCardList"

type NameCardListHistoryProps = {
    isPending: boolean
    toolId: number
    data: ChineseNamesDataArrayType[]
}


export const NameCardListHistory = memo<NameCardListHistoryProps>(
    ({data, isPending, toolId}) => {
        const t = useTranslations("ChineseNamesGenerator")
        const [, copy] = useCopyToClipboard()
        const {isFavorite, addFavorite} = useFavorites<ChineseNameDataType>(
            FAVORITES_SS_KEY + toolId,
            (it, v) => it.name === v,
        )

        const handleCopy = useCallback(
            (name: string) => {
                if (isPending) {
                    return
                }
                copy(name)
            },
            [copy, isPending],
        )

        const handleFavorite = useCallback(async (name: string) => {
            console.log("fav =>", name)
            if (isPending) {
                return
            }
            return fetchPost("/api/tools/chinese-names-generator/favorite", {
                name,
                toolId,
            }).then(() => addFavorite({name} as any))
        }, [addFavorite, isPending, toolId])
        const prevTotalCount = useMemo(
            () => (data ? data.reduce((v, next) => v + next.length, 0) : 0),
            [data],
        )

        return (
            <>
                <div className="flex items-center justify-between">
                    <h2 className="text-2xl font-bold text-black mb-4">
                        {t("previous-results.title")} ({prevTotalCount})
                    </h2>
                </div>
                {data.length ? (
                    <Accordion
                        itemClasses={{title: "text-medium"}}
                        defaultExpandedKeys={["item-0"]}
                    >
                        {data.map((items, index) => (
                            <AccordionItem
                                key={`item-${index}`}
                                aria-label={t("previous-results.item-title", {index: index + 1})}
                                subtitle={t("previous-results.item-title", {index: index + 1})}
                            >
                                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                                    {items.map((item, index) => (
                                        <NameCard
                                            key={item.name}
                                            {...item}
                                            isFavorite={isFavorite(item.name)}
                                            onCopy={handleCopy}
                                            onFavorite={handleFavorite}
                                        />
                                    ))}
                                </div>
                                <div className="w-full mt-2 text-end"><CopyAndDownload data={items}/>
                                </div>
                            </AccordionItem>
                        ))}
                    </Accordion>
                ) : (
                    <div className="mx-auto text-center py-5 text-gray-400 text-medium">
                        {t("previous-results.empty")}
                    </div>
                )}
            </>
        )
    },
)
NameCardListHistory.displayName = "NameCardListHistory"


type NameCardCompactListProps = {
    data: Pick<ChineseNameDataType, "name" | "pinyin" | "tone">[]
}
export const NameCardCompactList = memo<NameCardCompactListProps>(({data}) => {
    const t = useTranslations()
    return <div className="w-full mx-auto">
        {
            data.length ? (
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
                    {data.map((item: any) => (
                        <NameCardCompact
                            key={item.name} // 假定每个名字是唯一的
                            {...item}
                            onCopy={false}
                            onFavorite={false}
                        />
                    ))}
                </div>
            ) : (
                <div className="mx-auto text-center py-20 text-gray-400 text-medium">
                    {t("empty")}
                </div>
            )
        }
    </div>
})