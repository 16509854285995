export const RANDOM_CACHE_TYPE_CHINESE_NAMES = "_CHINESE_NAMES_"

// 性别
export const CHINESE_GENDERS = [
    {label: "male", value: "男性"},
    {label: "female", value: "女性"},
    {label: "neutral", value: "中性"},
    {label: "any", value: "任意"},
]
// 年龄段
export const CHINESE_AGE_RANGES = [
    {label: "baby", value: "婴儿"},
    {label: "child", value: "儿童"},
    {label: "teen", value: "少年"},
    {label: "youth", value: "青年"},
    {label: "adult", value: "中年"},
    {label: "old", value: "老年"},
    {label: "any", value: "任意"}
]
// 样式
export const CHINESE_NAME_STYLES = [
    {label: "popular", value: "流行"},
    {label: "classic", value: "经典"},
    {label: "modern", value: "现代"},
    {label: "simple", value: "简单"},
    {label: "elegant", value: "优雅"},
    {label: "ancient", value: "古风"},
    {label: "compound", value: "复姓"},
    {label: "double", value: "叠字"},
    {label: "any", value: "任意"}
]
// 姓氏
export const CHINESE_SURNAMES = [
    {label: "王(wang)",value: "王"},
    {label: "李(li)",value: "李"},
    {label: "张(zhang)",value: "张"},
    {label: "刘(liu)",value: "刘"},
    {label: "陈(chen)",value: "陈"},
    {label: "杨(yang)",value: "杨"},
    {label: "黄(huang)",value: "黄"},
    {label: "赵(zhao)",value: "赵"},
    {label: "吴(wu)",value: "吴"},
    {label: "周(zhou)",value: "周"},
    {label: "徐(xu)",value: "徐"},
    {label: "孙(sun)",value: "孙"},
    {label: "马(ma)",value: "马"},
    {label: "朱(zhu)",value: "朱"},
    {label: "胡(hu)",value: "胡"},
    {label: "郭(guo)",value: "郭"},
    {label: "何(he)",value: "何"},
    {label: "林(lin)",value: "林"},
    {label: "罗(luo)",value: "罗"},
    {label: "高(gao)",value: "高"},
    {label: "郑(zheng)",value: "郑"},
    {label: "梁(liang)",value: "梁"},
    {label: "谢(xie)",value: "谢"},
    {label: "宋(song)",value: "宋"},
    {label: "唐(tang)",value: "唐"},
    {label: "许(xu)",value: "许"},
    {label: "韩(han)",value: "韩"},
    {label: "冯(feng)",value: "冯"},
    {label: "曹(cao)",value: "曹"},
    {label: "彭(peng)",value: "彭"},
    {label: "曾(zeng)",value: "曾"},
    {label: "肖(xiao)",value: "肖"},
    {label: "邓(deng)",value: "邓"},
    {label: "田(tian)",value: "田"},
    {label: "董(dong)",value: "董"},
    {label: "潘(pan)",value: "潘"},
    {label: "袁(yuan)",value: "袁"},
    {label: "蔡(cai)",value: "蔡"},
    {label: "蒋(jiang)",value: "蒋"},
    {label: "余(yu)",value: "余"},
    {label: "于(yu)",value: "于"},
    {label: "杜(du)",value: "杜"},
    {label: "叶(ye)",value: "叶"},
    {label: "程(cheng)",value: "程"},
    {label: "魏(wei)",value: "魏"},
    {label: "苏(su)",value: "苏"},
    {label: "吕(lv)",value: "吕"},
    {label: "丁(ding)",value: "丁"},
    {label: "任(ren)",value: "任"},
    {label: "卢(lu)",value: "卢"},
    {label: "姚(yao)",value: "姚"},
    {label: "沈(shen)",value: "沈"},
    {label: "钟(zhong)",value: "钟"},
    {label: "姜(jiang)",value: "姜"},
    {label: "崔(cui)",value: "崔"},
    {label: "谭(tan)",value: "谭"},
    {label: "陆(lu)",value: "陆"},
    {label: "范(fan)",value: "范"},
    {label: "汪(wang)",value: "汪"},
    {label: "廖(liao)",value: "廖"},
    {label: "石(shi)",value: "石"},
    {label: "金(jin)",value: "金"},
    {label: "韦(wei)",value: "韦"},
    {label: "贾(jia)",value: "贾"},
    {label: "夏(xia)",value: "夏"},
    {label: "付(fu)",value: "付"},
    {label: "方(fang)",value: "方"},
    {label: "熊(xiong)",value: "熊"},
    {label: "白(bai)",value: "白"},
    {label: "孟(meng)",value: "孟"},
    {label: "秦(qin)",value: "秦"},
    {label: "邱(qiu)",value: "邱"},
    {label: "侯(hou)",value: "侯"},
    {label: "江(jiang)",value: "江"},
    {label: "尹(yin)",value: "尹"},
    {label: "薛(xue)",value: "薛"},
    {label: "闫(yan)",value: "闫"},
    {label: "段(duan)",value: "段"},
    {label: "雷(lei)",value: "雷"},
    {label: "龙(long)",value: "龙"},
    {label: "黎(li)",value: "黎"},
    {label: "史(shi)",value: "史"},
    {label: "陶(tao)",value: "陶"},
    {label: "贺(he)",value: "贺"},
    {label: "毛(mao)",value: "毛"},
    {label: "郝(hao)",value: "郝"},
    {label: "顾(gu)",value: "顾"},
    {label: "龚(gong)",value: "龚"},
    {label: "邵(shao)",value: "邵"},
    {label: "万(wan)",value: "万"},
    {label: "覃(tan)",value: "覃"},
    {label: "武(wu)",value: "武"},
    {label: "钱(qian)",value: "钱"},
    {label: "戴(dai)",value: "戴"},
    {label: "严(yan)",value: "严"},
    {label: "莫(mo)",value: "莫"},
    {label: "孔(kong)",value: "孔"},
    {label: "向(xiang)",value: "向"},
    {label: "常(chang)",value: "常"},
    {label: "any", value: "任意"}
]
// 灵感
export const CHINESE_NAME_INSPIRATIONS = [
    {label: "shijing", value: "诗经"},
    {label: "chuci", value: "楚辞"},
    {label: "zhongyao", value: "中药"},
    {label: "myth", value: "神话"},
    {label: "allusion", value: "典故"},
    {label: "celebrities", value: "名人"},
    {label: "tangshi", value: "唐诗"},
    {label: "songci", value: "宋词"},
    {label: "any", value: "任意"}
]
// 五行
export const CHINESE_WUXING = [
    {label: "gold", value: "金"},
    {label: "wood", value: "木"},
    {label: "water", value: "水"},
    {label: "fire", value: "火"},
    {label: "earth", value: "土"},
    {label: "any", value: "任意"},
]

export const CHINESE_NAME_FORM_ENGLISH = [
    {
        key: "gender",
        options: CHINESE_GENDERS,
        type: "select"
    }
]

// 选项
export const CHINESE_NAMES_OPTIONS = [
    {
        key: "gender",
        options: CHINESE_GENDERS,
        type: "select"
    },
    {
        key: "nameStyle",
        options: CHINESE_NAME_STYLES,
        type: "select"
    },
    {
        key: "nameInspiration",
        options: CHINESE_NAME_INSPIRATIONS,
        type: "select"
    },
    {
        key: "wuxing",
        options: CHINESE_WUXING,
        type: "select"
    },
    {
        key: "birthday",
        type: "date"
    },
    {
        key: "customRequirements",
        type: "input",
        placeholder: "",
    }
]